import React, { useState, useEffect, useContext } from 'react'
import { Container, Typography, Box, makeStyles } from '@material-ui/core'
import { TitleH2Bold, SectionHeaderSmall } from '../../theme/typography'
import { uppercaseString } from '../../utils'
import PlanTabs from './PlanTabs'
import PropTypes from 'proptypes'
import styled from 'styled-components'
import initial from '../../data/ipad/plans.json'
import Svg from 'react-inlinesvg'
import WorldLeft from '../../images/bgimages/images-world-map-left.svg'
import WorldRight from '../../images/bgimages/images-world-map-right.svg'

import { LocaleContext } from '../../contexts/LocaleProvider'

const useStyles = makeStyles((theme) => ({
  worldMapLeft: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      position: 'absolute !important',
      top: 100,
      left: -250
    },
    '@media (min-width: 1400px)': {
      left: -100
    },
    '@media (min-width: 1700px)': {
      left: 0
    }
  },
  worldMapRight: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      position: 'absolute !important',
      top: 100,
      right: -320
    },
    [theme.breakpoints.up('lg')]: {
      right: -250
    },
    '@media (min-width: 1400px)': {
      right: -150
    },
    '@media (min-width: 1700px)': {
      right: 0
    }
  }
}))

const PlansBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  [theme.breakpoints.up('md')]: {
    minHeight: 1080
  }
}))
const TitleTop = styled(SectionHeaderSmall)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  textTransform: 'none'
}))
const ContentText = styled(Typography)(({ theme }) => ({
  maxWidth: 800,
  margin: 'auto',
  marginBottom: theme.spacing(5)
}))

function TabletPlans(props) {
  const classes = useStyles(props)

  const regions = props.plans
    .filter((x) => x.footprintType === 'Local')
    .map((x) => x.region)
    .filter((item, index, list) => list.indexOf(item) === index)

  const countries = props.plans
    .map((x) => x.footprintCountries)
    .flat()
    .filter((element, index, list) => list.indexOf(element) === index)

  return (
    <PlansBox pt={8} pb={7}>
      <Svg className={classes.worldMapLeft} src={WorldLeft} alt="" />
      <Svg className={classes.worldMapRight} src={WorldRight} alt="" />
      <Container maxWidth="lg" align="center">
        <TitleTop component="h1" gutterBottom>
          {uppercaseString(props.titleTop)}
        </TitleTop>
        <TitleH2Bold component="h2" gutterBottom style={{ textTransform: 'none' }}>
          {uppercaseString(props.title)}
        </TitleH2Bold>
        {props.content && (
          <ContentText variant="body1" color="textPrimary">
            {props.content}{' '}
          </ContentText>
        )}
        <PlanTabs {...props} plans={props.plans} countries={countries} regions={regions} />
      </Container>
    </PlansBox>
  )
}

export default TabletPlans

TabletPlans.propTypes = {
  title: PropTypes.string,
  topText: PropTypes.string,
  content: PropTypes.string
}

TabletPlans.defaultProps = {
  plans: []
}
