import React, { useState, useEffect } from 'react'
import ConsumerLayout from '../../components/Layouts/ConsumerLayout'
import Hero from '../../components/Banners/Hero'
import ItemCarousel from '../../components/Consumer/ItemCarousel'
import TabletPlans from '../../components/Consumer/TabletPlans'
import DevicesCarousel from '../../components/Consumer/DevicesCarousel'
import ImageTextBlock from '../../components/Other/ImageTextBlock'
import FloatingBlock from '../../components/Consumer/FloatingBlock'
import HeroConsumer from '../../components/Consumer/HeroConsumer'
import TwoBlocks from '../../components/Consumer/TwoBlocks'
import Logos from '../../components/Other/Logos'
import CustomerCarousel from '../../components/Consumer/CustomerCarousel'
import { ParallaxProvider } from 'react-scroll-parallax'
import { makeStyles } from '@material-ui/core'
import { graphql } from 'gatsby'
import FAQList from '../../components/Other/FAQList'
import { useExperiment, emitter, experimentDebugger } from '@marvelapp/react-ab-test'
import LocaleProvider from '../../contexts/LocaleProvider'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import Svg from 'react-inlinesvg'
import CombinedBlue2a from '../../images/bgimages/combined-blue2a.svg'
import CombinedBlueLarge from '../../images/bgimages/combined-blue2-large.svg'
import CombinedGrey from '../../images/bgimages/combined-grey.svg'
import ShapeMask from '../../images/bgimages/shape-mask.svg'
import HalfCircle from '../../images/bgimages/half-circle.svg'
import HeroBgImage from '../../images/bgimages/shapes-ipad.svg'

const variants = ['A', 'B']
emitter.defineVariants('esim-for-tablet', variants)

const useStyles = makeStyles((theme) => ({
  gradient: {
    position: 'relative',
    overflow: 'hidden',
    backgroundImage:
      'linear-gradient(to bottom, #299dc1, rgba(41, 157, 193, 0.8) 50%, rgba(41, 157, 193, 0.6))',
    padding: '10rem 0 0'
  },
  devicesCarouselBgImage: {
    position: 'absolute',
    zIndex: 0,
    bottom: '-100%',
    width: 1370,
    left: '58%',
    transform: 'translateX(-50%)'
  },
  devicesCarouselBottom: {
    position: 'relative',
    backgroundColor: theme.colors.white,
    height: '7rem',
    padding: '3rem 0 0'
  },
  bgGreyGradient: {
    backgroundImage: 'linear-gradient(to bottom, #ffffff,#f7f6f6 50%)',
    position: 'relative',
    overflow: 'hidden'
  },
  relativePos: {
    position: 'relative'
  },
  contentWrap: {
    position: 'relative',
    overflow: 'hidden',
    paddingTop: 40
  },
  bgWhite: {
    backgroundColor: '#ffffff',
    position: 'relative'
  },
  halfCircle: {
    position: 'absolute',
    left: -500,
    top: -405,
    zIndex: 0,
    width: 1500,
    maxWidth: 'none',
    [theme.breakpoints.up('md')]: {
      top: -505,
      width: 2880,
      height: 1604
    },
    '@media (min-width: 2360px)': {
      width: 3500,
      height: 2204
    }
  },
  shape: {
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%)',
    top: -505,
    width: 1500,
    height: 2250,
    zIndex: 0,
    maxWidth: 'none',
    [theme.breakpoints.up('md')]: {},
    [theme.breakpoints.up('lg')]: {
      top: -505,
      width: 3880,
      height: 3880
    }
    // '@media (min-width:1490px)': {
    //   width: '100vw',
    //   height: 'auto'
    // }
  },
  bgGrey: {
    position: 'relative',
    backgroundColor: theme.colors.cream
  },
  circle1: {
    position: 'absolute',
    left: -600,
    bottom: -80,
    '@media (min-width: 960px)': {
      left: -450
    }
  },
  circle2: {
    position: 'absolute',
    right: -600,
    bottom: 80,
    '@media (min-width: 960px)': {
      right: -450
    }
  },
  circleBlue: {
    display: 'none',
    '@media (min-width: 960px)': {
      display: 'block',
      position: 'absolute',
      right: 60,
      bottom: 0,
      width: 150
    }
  },
  plansPadding: {
    paddingBottom: '5rem'
  }
}))

function TabletPage({
  data: {
    datoCmsSite,
    datoCmsIpadLandingPage,
    allIpadPlan: { nodes: plans }
  },
  pageContext: { menu, cookiePrompt, footer, locale }
}) {
  const [ready, setReady] = useState(false)
  const { selectVariant } = useExperiment('esim-for-tablet')
  experimentDebugger.enable()

  const Page = (props) => {
    const classes = useStyles(props)
    return (
      <ConsumerLayout menu={menu} footer={footer} cookiePrompt={cookiePrompt}>
        <HelmetDatoCms
          seo={datoCmsIpadLandingPage.seoMetaTags}
          favicon={datoCmsSite.faviconMetaTags}
        />

        <div className={classes.contentWrap}>
          {!props.variantEnabled && (
            <>
              <HeroConsumer
                tablet={true}
                fullwidth={true}
                bgcolor="gradient"
                image={props.heroImage}
                topText={props.heroTitle}
                title={props.heroSubtitle}
                content={props.heroDescription}
                videoUrl={props.heroVideoLink}
                bgimage={HeroBgImage}
                selectDevices={[
                  {
                    fullwidth: true,
                    compatibilityTitle: props.compatibilityTitle,
                    compatibilityDescription: props.compatibilityDescription,
                    compatibilityPlaceholderText: props.compatibilityPlaceholderText,
                    compatibilityModels: JSON.parse(props.compatibilityModels).map((model) => {
                      return {
                        label: model,
                        value: model.split(' ').join('_')
                      }
                    }),
                    compatibilityCtaOnClick: () => {
                      window.open(props.appStoreLink)
                    },
                    compatibilityCtaText: props.compatibilityCtaText
                  }
                ]}
              />

              {props.howItWorksSlides.length > 0 && (
                <div className={classes.gradient}>
                  <Svg
                    src={CombinedBlueLarge}
                    alt="bg-circle-blue-large"
                    className={classes.devicesCarouselBgImage}
                  />
                  <DevicesCarousel
                    minHeight={510}
                    autoplay={true}
                    list={props.howItWorksSlides.map((slide) => {
                      return {
                        topTitle: slide.topTitle,
                        title: slide.title,
                        content: slide.description,
                        image: slide.ipadScreenImage,
                        nextButton: slide.stepText,
                        icon: slide.stepImage,
                        showStepTextAsButton: slide.showStepTextAsButton,
                        smallText: slide.footer
                      }
                    })}
                    iphoneImage={props.howItWorksIpadOutline}
                    bgcolor={false}
                    iPad={true}
                    textBottom={props.howItWorksFooterLink}
                  />
                  <div className={classes.devicesCarouselBottom}></div>
                </div>
              )}
            </>
          )}

          <div className={`${classes.relativePos}`}>
            <div
              className={`${classes.contentWrap} ${
                !props.variantEnabled ? classes.bgGreyGradient : classes.plansPadding
              }`}
            >
              <LocaleProvider>
                <TabletPlans
                  locale={locale}
                  plans={plans}
                  titleTop={props.variantEnabled ? props.variantHeroTitle : ''}
                  title={props.variantEnabled ? props.variantHeroSubtitle : props.plansTitle}
                  durationSingularLabel={props.plansDurationDay}
                  durationLabel={props.plansDurationDays}
                  globalPlanText={props.plansGlobalPlanText}
                  europePlanText={props.planEuropePlanText}
                  usaPlusPlanText={props.planUsaPlusPlanText}
                  showMoreText={props.plansShowMore}
                  freePlanEnabled={props.plansShowFreePlan}
                  freePlanAllowance={props.plansFreePlanAllowance}
                  freePlanPrice={props.plansFreePlanPrice}
                  freePlanDuration={props.plansFreePlanDuration}
                  freePlanUnit={props.plansFreePlanUnit}
                  searchPlaceholder={props.plansSearchPlaceholder}
                  noResultsText={props.plansNoSearchResults}
                  anchorId={props.plansAnchorId}
                  localTabText={props.plansLocalPlanTab}
                  regionalTabText={props.plansRegionalPlanTab}
                  globalTabText={props.plansGlobalPlanTab}
                  coverageTitle={props.plansCoverageNumberOfCountries}
                  coverageDescription={
                    props.plansCoveragePlanDescriptionNode?.childMarkdownRemark.html
                  }
                  howMuchDataLink={Object.create({
                    text: props.plansFooterLinkText,
                    href: props.plansFooterLinkUrl,
                    openIn: props.plansFooterLinkOpenNewTab
                  })}
                  modal={{
                    store: false,
                    adsEvent: 'get-started-ipad',
                    title: props.modalTitle,
                    content: props.modalContent,
                    ctas: true,
                    cancel: props.modalCancelText,
                    primary: {
                      text: props.modalLearnMoreText,
                      href: props.modalLearnMoreUrl,
                      target: props.modalLearnMoreOpenTab ? '_blank' : null
                    }
                  }}
                />
              </LocaleProvider>
              {!props.variantEnabled && props.reviewsReviews.length > 0 && (
                <CustomerCarousel
                  locale={locale}
                  largeTitle={true}
                  title={props.reviewsTitle}
                  list={props.reviewsReviews}
                />
              )}

              <img src={CombinedGrey} alt="" className={classes.circle1} />
              <img src={CombinedGrey} alt="" className={classes.circle2} />
              <img src={CombinedBlue2a} alt="" className={classes.circleBlue} />
            </div>

            <div className={!props.variantEnabled ? classes.bgGrey : ''}>
              {!props.variantEnabled && props.companyLogos.length > 0 && (
                <Logos
                  hover={true}
                  noBorders={true}
                  logos={props.companyLogos.map((logo) => {
                    return {
                      image: logo.image,
                      alt: logo.image.alt,
                      link: logo.link,
                      openNewTab: logo.openNewTab
                    }
                  })}
                />
              )}
              {props.cardCarouselCards.length > 0 && (
                <ItemCarousel
                  bgcolor="dark"
                  type="bluebox"
                  list={props.cardCarouselCards.map((card) => {
                    return {
                      title: card.title,
                      content: card.description,
                      icon: card.icon
                    }
                  })}
                />
              )}
            </div>

            <div className={classes.relativePos}>
              {props.variantEnabled ? (
                <>
                  <img className={classes.shape} src={ShapeMask} alt="half circle" />
                  <ParallaxProvider>
                    <ImageTextBlock
                      textWhite={true}
                      customVideoPlayIcon={'../../../images/icons/arrow-play-pink.svg'}
                      titleLarge={props.variantHowItWorksTitle}
                      text={props.variantHowItWorksDescription}
                      image={props.variantHowItWorksImage}
                      bgImgColor="#a2c8ce"
                      videoUrl={props.variantHowItWorksVideoLink}
                    />
                  </ParallaxProvider>
                  {props.reviewsReviews.length > 1 && (
                    <CustomerCarousel
                      bgcolor={false}
                      textWhite={true}
                      title={props.reviewsTitle}
                      list={props.reviewsReviews}
                    />
                  )}
                </>
              ) : (
                <div className={classes.relativePos}>
                  <img className={classes.halfCircle} src={HalfCircle} alt="half circle" />
                  <TwoBlocks
                    color="white"
                    bgcolor="#48abc9"
                    ipad={true}
                    leftIconTextBlock={Object.create({
                      title: props.twoItemIconTextAreaLeftTitle,
                      description: props.twoItemIconTextAreaLeftText,
                      imageSrc: props.twoItemIconTextAreaLeftIcon,
                      cta: {
                        text: props.twoItemIconTextAreaLeftCtaText,
                        href: props.twoItemIconTextAreaLeftCtaLink,
                        target: props.twoItemIconTextAreaLeftCtaOpenNewTab ? '_blank' : ''
                      }
                    })}
                    rightIconTextBlock={Object.create({
                      title: props.twoItemIconTextAreaRightTitle,
                      description: props.twoItemIconTextAreaRightText,
                      imageSrc: props.twoItemIconTextAreaRightIcon,
                      cta: {
                        text: props.twoItemIconTextAreaRightCtaText,
                        href: props.twoItemIconTextAreaRightCtaLink,
                        target: props.twoItemIconTextAreaLeftCtaOpenNewTab ? '_blank' : ''
                      }
                    })}
                  />
                </div>
              )}
            </div>

            <FloatingBlock
              small={true}
              reverse={true}
              left="-40px"
              right="auto"
              width={550}
              maxWidth={550}
              colortop={props.variantEnabled ? 'transparent' : '#48abc9'}
              colorbottom="#023240"
              title={props.footerTitle}
              footerImage={props.footerImage}
              subtitle={props.footerDescription}
              ctas={[
                {
                  image: props.appStoreImage,
                  href: props.appStoreLink,
                  appStore: 'Apple'
                },
                {
                  text: props.footerCtaText,
                  href: props.footerCtaLink,
                  target: props.footerCtaOpenNewTab ? '_blank' : '',
                  adsEvent: 'get-started-ipad'
                }
              ]}
            />
          </div>
          <Hero
            title={props.contactSupportBannerTitle}
            subtitle={props.contactSupportBannerDescription}
            ctas={[
              {
                text: props.contactSupportBannerCtaText,
                href: props.contactSupportBannerCtaLink,
                target: props.contactSupportBannerCtaOpenNewTab ? '_blank' : ''
              }
            ]}
            bgColor="#01313f"
            color="#ffffff"
          />

          {props.faqItems.length > 0 && (
            <div className={classes.bgWhite}>
              <FAQList
                feedbackEnabled={false}
                large={true}
                title={props.faqTitle}
                list={props.faqItems}
              />
            </div>
          )}
        </div>
      </ConsumerLayout>
    )
  }

  const A = <Page {...datoCmsIpadLandingPage} variantEnabled={false} />
  const variant = selectVariant({
    A,
    B: <Page {...datoCmsIpadLandingPage} variantEnabled={true} />
  })

  useEffect(() => {
    if (!datoCmsIpadLandingPage.variantEnabled) {
      return
    }

    let variant = 'A'
    const variantUrl = new URLSearchParams(window.location.search)
    if (variantUrl.get('variant')) {
      variant = variantUrl.get('variant').toUpperCase()
      if (variants.includes(variant)) {
        emitter.setActiveVariant('esim-for-tablet', variant)
      }
    }

    setReady(true)
  }, [])

  if (datoCmsIpadLandingPage.variantEnabled && !ready) {
    return <></>
  }

  if (!datoCmsIpadLandingPage.variantEnabled) {
    return A
  }

  return <>{variant}</>
}

export default TabletPage

export const query = graphql`
  query IpadLandingPage($locale: String!) {
    datoCmsSite(locale: { eq: $locale }) {
      faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
      }
    }
    datoCmsIpadLandingPage(locale: { eq: $locale }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      heroTitle
      heroSubtitle
      heroDescription
      heroImage {
        url
        alt
        gatsbyImageData(width: 789, height: 300)
      }
      appStoreImage {
        url
        alt
        gatsbyImageData(height: 47)
      }
      appStoreLink
      heroVideoLink
      compatibilityTitle
      compatibilityDescription
      compatibilityPlaceholderText
      compatibilityModels
      compatibilityCtaText
      compatibilityCtaLink
      compatibilityCtaOpenNewTab
      howItWorksIpadOutline {
        url
        alt
        gatsbyImageData(width: 442)
      }
      howItWorksSlides {
        topTitle
        title
        description
        footer
        ipadScreenImage {
          url
          alt
          gatsbyImageData(width: 398)
        }
        stepImage {
          url
          alt
          gatsbyImageData
        }
        stepText
        showStepTextAsButton
      }
      howItWorksFooterLink
      plansAnchorId
      plansTitle
      plansSearchPlaceholder
      plansNoSearchResults
      plansCountryIso
      plansLocalPlanTab
      plansRegionalPlanTab
      plansGlobalPlanTab
      plansCoverageNumberOfCountries
      plansCoveragePlanDescriptionNode {
        childMarkdownRemark {
          html
        }
      }
      plansShowMore
      plansGlobalPlanText
      plansDurationDay
      plansDurationDays
      plansShowFreePlan
      plansFreePlanAllowance
      plansFreePlanDuration
      plansFreePlanUnit
      plansFreePlanPrice
      plansFooterLinkText
      plansFooterLinkUrl
      plansFooterLinkOpenNewTab
      modalTitle
      modalContent
      modalCancelText
      modalLearnMoreText
      modalLearnMoreUrl
      modalLearnMoreOpenTab
      reviewsTitle
      reviewsReviews {
        title
        content
        date
      }
      companyLogos {
        image {
          url
          alt
          gatsbyImageData(height: 60)
        }
        link
        linkText
        openNewTab
      }
      cardCarouselCards {
        title
        description
        icon {
          url
          gatsbyImageData(height: 150)
        }
      }
      twoItemIconTextAreaLeftIcon {
        url
        alt
        gatsbyImageData(height: 90, width: 122)
      }
      twoItemIconTextAreaLeftTitle
      twoItemIconTextAreaLeftText
      twoItemIconTextAreaLeftCtaText
      twoItemIconTextAreaLeftCtaLink
      twoItemIconTextAreaLeftCtaOpenNewTab
      twoItemIconTextAreaRightIcon {
        url
        alt
        gatsbyImageData(height: 90, width: 122)
      }
      twoItemIconTextAreaRightTitle
      twoItemIconTextAreaRightText
      twoItemIconTextAreaRightCtaText
      twoItemIconTextAreaRightCtaLink
      twoItemIconTextAreaLeftCtaOpenNewTab
      footerImage {
        url
        alt
        gatsbyImageData
      }
      footerTitle
      footerDescription
      footerCtaText
      footerCtaLink
      footerCtaOpenNewTab
      contactSupportBannerTitle
      contactSupportBannerDescription
      contactSupportBannerCtaText
      contactSupportBannerCtaLink
      contactSupportBannerCtaOpenNewTab
      variantEnabled
      variantHeroTitle
      variantHeroSubtitle
      variantHeroDescription
      variantHowItWorksTitle
      variantHowItWorksDescription
      variantHowItWorksImage {
        url
        alt
        gatsbyImageData
      }
      variantHowItWorksVideoLink
      faqTitle
      faqItems {
        faqId
        title
        content
      }
    }
    allIpadPlan(filter: { locale: { eq: $locale } }) {
      nodes {
        footprintType
        footprintCountries {
          iso
          name
        }
        name
        region
        iso
        iconName
        allowances {
          allowance
          unit
          duration
          prices {
            currency
            price
          }
        }
      }
    }
  }
`
